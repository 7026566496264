import { goto } from '$app/navigation';
import { addFeedback } from '$lib/helpers/feedback';
import { timer } from '$lib/stores';
import { type HandleClientError } from '@sveltejs/kit';

const errorPatterns = {
  '/tickets': 'Ticket não encontrado!',
  '/contacts': 'Contato não encontrado!',
  '/companies': 'Empresa não encontrada!',
  '/settings/kb/posts': 'Artigo não encontrado!',
  '/settings/automations': 'Automação não encontrada!'
};

function getMatchingPattern(url: string): string | null {
  for (const pattern in errorPatterns) {
    const regex = new RegExp(`^${pattern}(?:/|$)`);
    if (regex.test(url)) {
      return pattern;
    }
  }
  return null;
}

export const handleError: HandleClientError = ({ error, event }) => {
  const errorId = crypto.randomUUID();

  const token = localStorage.getItem('token');

  if (!token) {
    return {
      message: 'Usuário não autenticado',
      errorId
    };
  }

  if (error) {
    const {
      params,
      url: { pathname }
    } = event;

    const pattern = getMatchingPattern(pathname) as keyof typeof errorPatterns;

    if (pattern) {
      if (pattern === '/tickets') {
        let value = localStorage.getItem('timer');
        if (value) {
          const parsed = JSON.parse(value);
          if (parsed.id == params.ticket_id) {
            localStorage.removeItem('timer');
            timer.set(null);
          }
        }
      }
      addFeedback({ type: 'error', message: errorPatterns[pattern] || 'Esta página não existe!' });
      goto(pattern || '/');
    }
  }

  return {
    message: 'Whoops!',
    errorId
  };
};
