import { feedbackMessages } from '$lib/stores';
import type { FeedbackMessageType } from '$lib/interfaces';
import { get } from 'svelte/store';

export const addFeedback = (feedback: FeedbackMessageType) => {
    feedbackMessages.set([...get(feedbackMessages), feedback])
    setTimeout(() => {
        const items: FeedbackMessageType[] = get(feedbackMessages)
        feedbackMessages.set(items.toSpliced(0,1))
    }, 5000)
}