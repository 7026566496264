import type {
  AccountDataType,
  AgentType,
  AutomationType,
  CategoryType,
  ChatType,
  CustomFieldType,
  KbCategoryType,
  KbPostType,
  KbTagType,
  OptionsType,
  ProfileType,
  SquadsType,
  TagType,
  TicketType,
  WorkflowType
} from '$lib/interfaces';
import { writable, type Writable } from 'svelte/store';
import type { FeedbackMessageType } from './components/types';

const optionsType: OptionsType[] = [];

export const account: Writable<AccountDataType> = writable();

const agentData: Array<AgentType> = [];
export const agents = writable(agentData);

const categoryData: CategoryType[] = [];
export const categories = writable(categoryData);

const chatData: ChatType | null = null;
export const chat = writable(chatData);

export const companies = writable(optionsType);

const customFieldData: CustomFieldType[] = [];
export const customFields = writable(customFieldData);

const squadsData: SquadsType[] = [];
export const squads = writable(squadsData);

const workflowsData: WorkflowType[] = [];
export const workflows = writable(workflowsData);

const ticketData: TicketType | null = null;
export const ticket: Writable<TicketType | null> = writable(ticketData);
export const ticket_id = writable(0);
export const ticket_errors: Writable<string[]> = writable([]);
export const ticket_automations: Writable<AutomationType[]> = writable([]);

const ticket_fieldsData: CustomFieldType[] = [];
export const ticket_fields = writable(ticket_fieldsData);

const contact_fieldsData: CustomFieldType[] = [];
export const contact_fields = writable(contact_fieldsData);

const company_fieldsData: CustomFieldType[] = [];
export const company_fields = writable(company_fieldsData);

const isBrowser = typeof window !== 'undefined';
const jwt = isBrowser && window?.localStorage?.getItem('token');
export const token = writable(jwt);

export const workflow: Writable<WorkflowType> = writable();

export const activeRequests = writable(0);

const profileData: ProfileType = {
  id: 0,
  first_name: '',
  last_name: '',
  department: '',
  job_function: '',
  avatar: null,
  language: 'pt',
  role: 'viewer',
  username: '',
  settings: {
    filter: {},
    signature: '',
    recent_first: false,
    report_range: '',
    view: '',
    workflow: '',
    sound: false
  }
};

export const profile = writable(profileData);

export const sidebarCollapsed = writable(!isBrowser ? true : window?.localStorage?.getItem('sidebar_open') !== '0');

const feedbacks: FeedbackMessageType[] = [];
export const feedbackMessages = writable(feedbacks);
export const waTemplates = writable([]);

const tagData: TagType[] = [];
export const tags = writable(tagData);

const kbCategoriesData: KbCategoryType[] = [];
export const kbCategories = writable(kbCategoriesData);

const kbTagsData: KbTagType[] = [];
export const kbTags = writable(kbTagsData);

const kbPostsData: KbPostType[] = [];
export const kbPosts = writable(kbPostsData);

const surveyData: any[] = [];
export const surveys = writable(surveyData);

const ticketsListData: TicketType[] = [];
export const ticketsList = writable(ticketsListData);

const automationsData: AutomationType[] = [];
export const automationsStore = writable(automationsData);

const timerData: string | null = null;
export const timer = writable(timerData);
